import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Yes... this is create-react-app.... the default one..</p>
        <p>
          It's deployed on Exabytes.. if you land here.. the DNS is not
          configured properly.
        </p>
      </header>
    </div>
  );
}

export default App;
